
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "QuotationDetails",

  setup() {
    const store = useStore();
    const route = useRoute();
    const sync = ref([] as any);
    const result_conditions = ref([] as any);
    const result_quotations = ref([] as any);
    const activeNames = ref(["1", "2"]);
    const form = ref({
      created_date: "",
      code: "",
      office: "",
      entity: "",
      price: 0,
      units: 0,

      mode: "",
      flow: "",
      origin: "",
      destiny: "",
      pol: "",
      pod: "",
      cedido: "No",
      km: 0,
      model: "",
      equipment: "",
      equipment_type: "General",
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/manage_quotations", label: "manage_quotations" },
      ]);
      ApiService.get(`/api/synchronize_criterias`).then(({ data }) => {
        sync.value = data;
      });
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length > 0 && paramsRoute.id) {
        store.commit("setLoadinga", false);
        ApiService.get(`/api/quote/${paramsRoute.id}`).then(({ data }) => {
          form.value.created_date = data.created_date;
          form.value.code = data.code;
          form.value.units = data.units;
          form.value.office = data.office.name;
          form.value.price = data.price;

          data.results.forEach((x) => {
            const temp = JSON.parse(x.conditions);
            temp.forEach((y) => {
              const filtered = result_conditions.value.filter(
                (z) => z.criteria_id === y.criteria_id
              );
              if (!filtered.length) {
                result_conditions.value.push(y);
              }
            });
          });

          result_conditions.value.forEach((x) => {
            const criteria = sync.value.find(
              (y) => y.criteria_id === x.criteria_id
            );
            switch (criteria.description) {
              case "modo":
                form.value.mode = x.show;
                break;
              case "flujo":
                form.value.flow = x.show;
                break;
              case "origen":
                form.value.origin = x.show;
                break;
              case "pol":
                form.value.pol = x.show;
                break;
              case "pod":
                form.value.pod = x.show;
                break;
              case "destino":
                form.value.destiny = x.show;
                break;
              case "cliente":
                form.value.entity = x.show;
                break;
              case "distancia":
                form.value.cedido = x.value > 0 ? "iyes" : "ino";
                form.value.km = x.value;
                break;
              case "equipamiento":
                form.value.equipment = x.show;
                break;
              case "imo":
                form.value.equipment_type = "Peligrosa";
                break;
            }
          });

          data.results.forEach((x) => {
            const rates = JSON.parse(x.rates);
            if (rates[0].type === "simple" || rates[0].type === "range") {
              result_quotations.value.push({
                code: x.billing_concept.code,
                description: x.billing_concept.name,
                surchage: rates[0].is_surcharge == 1 ? "+" : "-",
                currency: x.currency.code,
                unit_price: rates[0].fixed_price,
                units: form.value.units,
                price: x.price,
              });
            } else if (rates[0].type === "percent") {
              result_quotations.value.push({
                code: x.billing_concept.code,
                description: x.billing_concept.name,
                surchage: rates[0].is_surcharge == 1 ? "+" : "-",
                currency: x.currency.code,
                unit_price: rates[0].fixed_price
                  ? rates[0].fixed_price
                  : rates[0].percent,
                units: form.value.units,
                price: x.price,
              });
            }
          });
        });
      }
    });

    return {
      activeNames,
      form,
      sync,
      result_conditions,
      result_quotations,
    };
  },
};
